import React, { useEffect, useState } from "react";

export default function FooterAddressView({
  shippingInfo,
  customShippingFormref,
  contactInfo,
}) {
  const [data, setData] = useState({});

  useEffect(() => {
    setData({
      firstName: shippingInfo?.shipFirstName || shippingInfo?.FirstName,
      lastName: shippingInfo?.shipLastName || shippingInfo?.LastName,
      address1:
        (shippingInfo?.shipAddress1 && shippingInfo?.shipAddress1) ||
        shippingInfo?.ShippingAddress1,
      address2:
        shippingInfo?.shipAddress2 || customShippingFormref?.ShippingAddress2,
      city: shippingInfo?.shipCity || customShippingFormref?.ShippingCity,
      state:
        shippingInfo?.shipState || customShippingFormref?.ShippingState?.label,
      suite:
        shippingInfo?.ShippingSuite || customShippingFormref?.ShippingSuite,
      country:
        shippingInfo?.shipCountryName ||
        customShippingFormref?.ShippingCountry?.label,
      zipCode: shippingInfo?.shipZipcode || customShippingFormref?.ShippingZip,
      phone:
        shippingInfo?.shipPhone ||
        customShippingFormref?.ShippingPhone ||
        contactInfo?.phone,
      company: shippingInfo?.shipCompany,
      email:
        shippingInfo?.shipEmail ||
        customShippingFormref?.ShippingEmail ||
        contactInfo?.email,
    });
  }, [shippingInfo]);

  return (
    <>
      <div className="w-full mb-[30px] px-[13px] flex">
        <div className="border border-gray-border rounded-[5px] overflow-hidden w-full">
          <div className="bg-light-gray flex flex-wrap justify-between items-center pl-[10px] pr-[10px] pt-[6px] pb-[6px] rounded-t-[5px] overflow-hidden">
            <span className="text-normal-text">Ship to:</span>
          </div>

          <div className="p-[15px]">
            {/* <div className="flex items-center p-1">
              <span className="material-icons text-[20px] pt-1 pl-1">
                person_pin
              </span>
              <div className="text-medium-text last:mb-0">
                {data?.firstName + " " + data?.lastName}
              </div>
            </div> */}
            {data?.company && (
              <div className="flex items-center p-1">
                <span className="material-icons text-[20px] pt-1 pl-1">
                  store
                </span>
                <div className="text-medium-text last:mb-0">
                  {data?.company}
                </div>
              </div>
            )}
            <div className="items-center p-1">
              <div className="flex">
                <span className="material-icons text-[20px] pt-1 pl-1">
                  place
                </span>
                <div className="text-medium-text last:mb-0">
                  {data?.address1}
                  {data?.city || data?.state ? "," : ""}
                  {data?.address2 ? data?.address2 : ""}
                </div>
              </div>
              <div className="text-medium-text last:mb-0 pl-[24px]">
                {data?.city ? data?.city + ", " : ""}
                {data?.state ? data?.state + ", " : ""}
                {data?.zipCode}
              </div>
              <div className="text-medium-text last:mb-0 pl-[24px]">
                {data?.country}
              </div>
            </div>
            {/* {data?.email && (
              <div className="flex items-center p-1">
                <span className="material-icons text-[20px] pt-1 pl-1">
                  email
                </span>
                <div className="text-medium-text last:mb-0">{data?.email}</div>
              </div>
            )}
            {data?.phone && (
              <div className="flex items-center p-1">
                <span className="material-icons text-[20px] pt-1 pl-1">
                  call
                </span>
                <div className="text-medium-text last:mb-0">{data?.phone}</div>
              </div>
            )} */}
          </div>
        </div>
      </div>
    </>
  );
}
