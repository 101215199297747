import React from "react";

import FooterProductView from "components/section/pages/formBuilderPage/FooterProductView";
import FooterAddressView from "components/section/pages/formBuilderPage/FooterAddressView";
import FooterFilledCustomFormView from "components/section/pages/formBuilderPage/FooterFilledCustomFormView";
import { formTypes } from "constants";

const Thankyou = ({
  thankYouPageContent,
  fieldValues,
  customShippingFormref,
  orderResponse,
  contactInfo,
  formType,
}) => {
  return (
    <div className="text-2xl min-h-[30vh] max-w-[100vw] overflow-hidden break-words">
      <div className="my-4 text-center ">
        <div className="w-full text-description">
          <div className="bg-primary pt-[45px] pb-[60px] md:pb-[100px] text-center rounded-[8px] overflow-hidden mb-[30px]">
            <div className="mb-[10px]">
              <span className="material-symbols-outlined mr-[6px] font-thin text-[40px] text-primarytext">
                verified
              </span>
            </div>
            {thankYouPageContent?.thankYouTop ? (
              <div className="text-2xl-text font-bold mb-[10px]">
                <span
                  className="text-primarytext"
                  dangerouslySetInnerHTML={{
                    __html: thankYouPageContent?.thankYouTop,
                  }}
                />
              </div>
            ) : (
              <h4 className="text-primarytext">Thank you.</h4>
            )}
            {formType === formTypes?.filledUpForm && (
              <div className="text-medium-text font-bold mb-[20px]">
                <span className="text-primarytext">
                  Your Order Number is: <span>{orderResponse?.id}</span>
                </span>
              </div>
            )}
            <div className="text-normal-text">
              <span className="text-primarytext">
                {"You will receive an order confirmation email at: "}
                <span>{orderResponse?.email || contactInfo?.email}</span>
              </span>
            </div>
          </div>
        </div>
      </div>

      {formType === formTypes?.filledUpForm && (
        <div className="border border-gray-border rounded-[5px] overflow-hidden mb-[30px]">
          <div className="bg-light-gray flex flex-wrap justify-between items-center pl-[10px] pr-[10px] pt-[6px] pb-[6px] rounded-t-[5px] overflow-hidden">
            <span className="text-normal-text">Detailed Order Receipt</span>
          </div>
          <div>
            {/* ordered items */}
            {fieldValues?.cartItems && Array.isArray(fieldValues?.cartItems) ? (
              fieldValues?.cartItems.map((cartItemsObj) => (
                <FooterProductView cartItemsObj={cartItemsObj} />
              ))
            ) : (
              <></>
            )}
          </div>
        </div>
      )}

      {/* Filled custom Form */}
      <div className="">
        <FooterFilledCustomFormView pages={fieldValues?.pages} />
      </div>

      {/* ordered shipping address */}
      {fieldValues?.shippingInfo && formType === formTypes?.filledUpForm && (
        <>
          {fieldValues?.shippingInfo ? (
            <div className="flex flex-wrap mx-[-13px]">
              <FooterAddressView
                shippingInfo={fieldValues?.shippingInfo}
                customShippingFormref={customShippingFormref}
                contactInfo={contactInfo}
              />
            </div>
          ) : (
            <span className="text-red-600">No shipping address to show.</span>
          )}
        </>
      )}

      {thankYouPageContent?.thankYouBottom && (
        <div className="py-[10px] md:py-[20px] overflow-hidden rounded-[8px] bg-primary mb-[26px] text-center">
          <div className="md:pl-[30px] pl-[15px]">
            <div
              className="text-primarytext text-normal-text font-bold"
              dangerouslySetInnerHTML={{
                __html: thankYouPageContent?.thankYouBottom,
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default Thankyou;
