export const domains = {
  // formUrl: "https://pkhg.pkhealthgearonline.site",
  // formUrl: "https://sampletestprii.pkhealthgearonline.site",
  // formUrl: "https://formtestnew0013.www.dell",
  // formUrl: "https://real.www.blackswan/",
  // formUrl: "https://kelloggformusaa.www.kellogg",
  formUrl: "https://testtv.www.pkf",
};

// if we need to check out out in local thn make it false
export const isLive = true; //process.env.REACT_APP_IS_LIVE || false;
